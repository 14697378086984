import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';

const getEnvironmentEndPoint = () => {
  //console.log(process.env.NODE_ENV);
  return (process.env.NODE_ENV === 'production') ? "https://pnm77tjfc2.execute-api.ap-southeast-2.amazonaws.com/dev" :  "http://localhost:8080/local";
}


Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: "ap-southeast-2:fb8a1d50-2f12-463a-920c-b607090928f5",
      // REQUIRED - Amazon Cognito Region
      mandatorySignIn: false,
      region: "ap-southeast-2",
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: "ap-southeast-2_iuqleGGxE",
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: "1j0bmi28dj8pmopst40gk9lajr"
    },
    API: {
      endpoints: [
        {
          name: "dev-API",
          endpoint: getEnvironmentEndPoint(),
          region: "ap-southeast-2"
        }
      ]
    }//,
    // Storage: {
    //   AWSS3: {
    //     bucket: '', //REQUIRED -  Amazon S3 bucket
    //     region: 'ap-southeast-2', //OPTIONAL -  Amazon service region
    //   }
    // }
  });


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
