import './App.css';
import Home from './Home';
import React from 'react';
import {Auth} from 'aws-amplify';

import { AmplifyAuthenticator, AmplifySignIn, AmplifySignUp, AmplifyConfirmSignUp } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import Admin from './Admin';


const NoMatch = () => {
  let location = useLocation();

  return (
    <div>
      <header className="App-header">
        404: No match for the requested URL <code>{location.pathname}</code>
      </header>
    </div>
  );
}

const LoginUI = () => (
  <div className="AuthContainer" >
    <AmplifyAuthenticator usernameAlias='email' >
      <AmplifySignUp
        slot="sign-up"
        usernameAlias="email"
        formFields={[
          {
            type: "email",
            label: "Email address",
            placeholder: "Enter your email address...",
            required: true,
          },
          {
            type: "given_name",
            label: "Given name(s)",
            placeholder: "Enter given name(s) here...",
            required: true,
          },
          {
            type: "family_name",
            label: "Surname",
            placeholder: "Enter surname here...",
            required: true,
          },
          {
            type: "password",
            label: "Password",
            placeholder: "Please choose a password...",
            required: true,
          }
        ]}
      />
      <AmplifySignIn slot="sign-in" usernameAlias='email' hideSignUp={false}></AmplifySignIn>
      <AmplifyConfirmSignUp usernameAlias='email' headerText="Please check your email for verification code" slot="confirm-sign-up"></AmplifyConfirmSignUp>
    </AmplifyAuthenticator>
  </div>);

function App() {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();


  React.useEffect(() => {

    if (authState === undefined) {
      Auth.currentAuthenticatedUser().then(authData => {
        setAuthState(AuthState.SignedIn);
        setUser(authData);
      }).catch(() => { });
    }

    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData)
    });
  });

  return (
    ((authState === AuthState.SignedIn) && (user)) ?
      (<div className="App">
        <Router>
          <Switch>
            <Route exact path="/">
              <Home user={user} />
            </Route>
            <Route path="/admin">
              <Admin user={user} />
            </Route>
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Router>

      </div>) : <LoginUI />
  );
}

export default App;
