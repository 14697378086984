import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import {API} from 'aws-amplify';
const apiEndPoint='dev-API';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function UpdateGenre({ open, setOpen, allGenre, setAllGenre }) {
    const classes = useStyles();

    const [gid, setGid] = useState(0);
    const [newGenre, setNewGenre] = useState('');
    const [loading, setLoading] = useState(false);

    const handleGenreChange = (event) => {
        const gid=Number(event.target.value),
             gname=allGenre.find((g)=>(g.id === gid)).genre;
        setGid( gid || '');
        setNewGenre(gname)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdateGenre = async () => {
        try {
            console.log(gid,newGenre);
            setLoading(true);
            const res = await API.put(apiEndPoint, '/admin/genre', {
              body: {
                id: gid,
                genre: newGenre
              }
            });
            console.log(res);
            setOpen(false);
            setAllGenre((prevAllGenre) => {
                let newAllGenre = prevAllGenre.slice();
                const gind = newAllGenre.findIndex((g) => (g.id === gid))
                let genreItem = { ...newAllGenre[gind], genre: newGenre };
                newAllGenre[gind] = genreItem;
                return newAllGenre;
            });
          } catch (err) {
            console.log(err.response?.data?.message);
            alert('Error: '+err.response?.data?.message);
      
          } finally {
            setLoading(false);
          }
    }

    return (
        // <div>
        //   
        <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
            <DialogTitle>Modify Genres</DialogTitle>
            <DialogContent>
                <form className={classes.container}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="demo-dialog-select-label">Genre</InputLabel>
                        <Select
                            labelId="demo-dialog-select-label"
                            id="demo-dialog-select"
                            value={gid}
                            onChange={handleGenreChange}
                            input={<Input />}
                        >
                            <MenuItem value={0}>
                                <em>None</em>
                            </MenuItem>
                            {allGenre.map((g, ind) => (
                                <MenuItem key={ind} value={g.id}>{g.genre}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                    <TextField
                        required
                        id="outlined-required"
                        label="New genre title"
                        value={newGenre}
                        onChange={(ev) => setNewGenre(ev.target.value)}
                        variant="outlined"
                    />
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">
                    Cancel
          </Button>
                <Button onClick={handleUpdateGenre} color="primary" disabled={loading}>
                    UPDATE
          </Button>
            </DialogActions>
        </Dialog>
    );
}