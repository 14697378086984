import AuthorWorks from './AuthorWorks';

//videos might be added to this component
function Home({user}) {
    return (
        <header className="App-header">
          <AuthorWorks />
        </header>
    );
  }
  
  export default Home;
  