import D3Circle from './D3Circle'
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Paper , Grid, Box} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {API} from 'aws-amplify';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const apiEndPoint='dev-API';

const sleep = (delayInms) => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, delayInms);
    });
}

const retryIfFailed = async (apiName, path) => {

    let result;
    const retryCount = 2;
    for (let k = 0; k < retryCount; k++) {
        try {
            result = await API.get(apiName, path);
        }
        catch (err) {
            console.log(err);
        }
        if (result)
            return result;
        else {
            const waitTime = Math.pow(3, (k + 1));
            console.log(`Waiting ${waitTime} seconds before retry...`);
            await sleep(waitTime * 1000);
        }
    }
    throw new Error('All servers are busy. Please refresh this page after a few minutes!');
}

export default class AuthorWorks extends React.Component {

    constructor() {
        super();
        this.state = {'open': false, 'author': {}, 'loading': false };
      }


    async componentDidMount() {
        try {
            this.setState({ 'loading': true });
            const allAuthors = await retryIfFailed(apiEndPoint, '/client/authors'),
                allTexts = await API.get(apiEndPoint, '/client/texts'),
                filteredTexts = allTexts.filter((t) => ((allAuthors.find((val) => (val.id === t.author_id)).visible))),
                allGenre = await API.get(apiEndPoint, '/client/genre');

            //console.log(allAuthors);
            //console.log(filteredTexts);

            const that = this;


            // D3 Code to create the chart
            this._chart = D3Circle.create(
                this._rootNode,
                {
                    authors: allAuthors.filter((e) => (e.visible)),
                    texts: filteredTexts,
                    genres: allGenre
                }, {
                showAuthorCB: (author) => that.handleOpen(author)
            }
            );
        } catch (err) {
            alert(err.message);
            
            console.log(err.message);
        } finally {
            this.setState({ 'loading': false });
        }

        
    }

    handleOpen(author) {
        this.setState({open: true, 'author':author});
        console.log(author);
      };

    componentDidUpdate() {
        // D3 Code to update the chart
        // D3Circle.update(
        //    this._rootNode,
        //    this.props.data,
        //    this.props.config,
        //    this._chart
        // );
    }

    componentWillUnmount() {
       // D3Circle.destroy(this._rootNode);
    }

    _setRef(componentNode) {
        this._rootNode = componentNode;
    }
    handleClose() {
        this.setState({open: false});
        console.log('here');
      };

    render() {
        return (<>
            <Dialog
                fullWidth={true}
                maxWidth={'xl'}
                open={this.state.open}
                onClose={() => this.handleClose()}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">{this.state.author.name}</DialogTitle>
                <DialogContent style={{overflowY:'visible'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={2}>
                            <Paper elevation={5} style={{display:'flex'}}>
                                <img alt={this.state.author.name} src={'img/'+this.state.author.photourl} style={{width:'100%', borderRadius:'4px', overflowX:'auto'}}/> 
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={10} style={{display:'flex'}}>
                            <Paper  elevation={5}>
                                <Box
                                    component="blockquote"
                                    fontSize="1.25rem"
                                    marginBottom="1rem"
                                    textAlign="left"
                                >
                                    <Box
                                        component="p"
                                        marginBottom="0"
                                        fontWeight="300"
                                        lineHeight="1.7"
                                        fontSize="1rem"
                                    >
                                        <ReactMarkdown remarkPlugins={[remarkGfm]} >
                                         {this.state.author.minibio}
                                        </ReactMarkdown>
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item sm={12} style={{ display: 'flex', flexDirection:'column' }}>
                        <Paper  elevation={5} >
                            <Box style={{margin:'0 1rem',lineHeight:"1.7" }}>
                            <ReactMarkdown remarkPlugins={[remarkGfm]} >
                                {this.state.author.fullbio}
                            </ReactMarkdown>
                            </Box>
                            </Paper>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.handleClose()} color="primary">
                        Close
          </Button>
                </DialogActions>
            </Dialog>
            <Backdrop style={{
                zIndex: 1000,
                color: '#fff',
                display: 'flex',
                flexDirection: 'column'
            }} open={this.state.loading} >
                <CircularProgress color="inherit" />
                <span style={{marginTop: '1rem'}}>Initializing resources ...</span>
            </Backdrop>
            <div className="line-container" ref={this._setRef.bind(this)} />
        </>);
    }
}