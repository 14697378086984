import React, {useState, useEffect} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Container,Button} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {AmplifySignOut} from '@aws-amplify/ui-react';
import UpdateGenre from './UpdateGenre';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from '@material-ui/core/Input';
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField';




import {API} from 'aws-amplify';
const apiEndPoint='dev-API';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const useStyles = makeStyles((theme) =>({
  table: {
    minWidth: 700,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  authorDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 1rem'
  }
}));


const AddGenre = ({  allGenre, onAddGenre, loading }) => {
  const classes = useStyles();
  const [selectedGenre, setSelectedGenre] = useState(0);

  const handleAddClick = () =>{
    onAddGenre(selectedGenre);
    setSelectedGenre(0);
  }

 
  return (
    <React.Fragment>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Genre</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedGenre}
          label="Genre"
          onChange={(ev) => setSelectedGenre(Number(ev.target.value))}
        >
          <MenuItem value={0}>None</MenuItem>
          {allGenre.map((g,ind) => (
            <MenuItem key={ind} value={g.id}>{g.genre}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {loading ? <CircularProgress /> :
        <Button variant="contained" color="secondary" onClick={handleAddClick}>
          Add
      </Button>
      }
    </React.Fragment>
  );
}


function TextRow({text, allGenre}) {
  const [txt,setTxt]=useState(text);
  const [loading, setLoading] = useState(false);

  const handleDelete = async (txtid,gnrid,arr,ind) => {
    
    try {
      setLoading(true);
      const res = await API.put(apiEndPoint, '/admin/removegenrefromtext', {
        body: {
          textid: txtid,
          genreid: gnrid
        }
      });
      console.log(res);
      arr.splice(ind,1);
    } catch (err) {
      alert('Error: ', err.message);

    } finally {
      setLoading(false);
    }
  };

  const handleAddGenre = async (gnrid) => {

    try {
      setLoading(true);
      const res = await API.put(apiEndPoint, '/admin/addgenretotext', {
        body: {
          textid: txt.id,
          genreid: gnrid
        }
      });
      console.log(res);
      setTxt((currentTxt) => {
        const cmpaids=[...currentTxt.mapped_ids,gnrid];
        return {...currentTxt, mapped_ids:cmpaids};
      });
    } catch (err) {
      console.log(err.response?.data?.message);
      alert('Error: '+err.response?.data?.message);

    } finally {
      setLoading(false);
    }

  }


  const getGenreText = (id ) => {
    return allGenre.find((e) => (e.id === id))?.genre;
  }

  return (
    <StyledTableRow key={txt.id} selected={loading}>
    <StyledTableCell component="th" scope="row">
      {txt.id}
    </StyledTableCell>
    <StyledTableCell>{txt.publication_title+', '+txt.date_year}</StyledTableCell>
    <StyledTableCell>{txt.mapped_ids.map((gnr_id,ind)=> (
      <Chip key={ind} label={getGenreText(gnr_id)} onDelete={() => handleDelete(txt.id,gnr_id,txt.mapped_ids,ind)} color="primary" />
    ))}
    </StyledTableCell>
    <StyledTableCell>
      <AddGenre txtid={txt.id} allGenre={allGenre.filter((g)=>(txt.mapped_ids.indexOf(g.id) === -1))} onAddGenre={handleAddGenre} />
    </StyledTableCell>
  </StyledTableRow>
  );
}

function TextsTable({allTexts, allGenre}) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Text ID</StyledTableCell>
            <StyledTableCell>Publication Title</StyledTableCell>
            <StyledTableCell>Genres</StyledTableCell>
            <StyledTableCell>Add?</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allTexts.map((txt,rind) => (
            <TextRow key={rind} text={txt} allGenre={allGenre} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const checkIfIsAdmin = (user) => {
  const groups = user.signInUserSession.idToken.payload['cognito:groups'];
  return (Array.isArray(groups) && groups.indexOf("Admins") >=0);
}





const UpdateAuthor = ({ open, setOpen }) => {
  const classes = useStyles();
  const [allAuthors, setAllAuthors]= useState([]);
  const [currentAuthorId, setCurrentAuthorId]= useState(0);
  const [newMinibio, setNewMinibio] = useState('');
  const [newFullbio, setNewFullbio] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const 
          authors = await API.get(apiEndPoint, '/client/authors');

        console.log(authors);
        setAllAuthors(authors);

        

      } catch (err) {
        alert('An error occured', err.message);
        console.log(err);
      }
      finally {
        setLoading(false);
      }
    }
    loadData();

  }, []);


  const handleClose = () => {
      setOpen(false);
  };

  const handleAuthorChange = (ev) => {
    const authorId=Number(ev.target.value);
    setCurrentAuthorId(authorId);
    setNewMinibio((authorId === 0) ? '' : (allAuthors.find((e)=>(e.id === authorId)).minibio || ''));
    setNewFullbio((authorId === 0) ? '' : (allAuthors.find((e)=>(e.id === authorId)).fullbio || ''));
  }

  const handleUpdateAuthor = async () => {
     try {
       if (currentAuthorId === 0) {
         window.alert('Please select an author first');
         return;
       }
          setLoading(true);
          const res = await API.patch(apiEndPoint, '/admin/authors', {
            body: {
              id: currentAuthorId,
              minibio: newMinibio,
              fullbio: newFullbio
            }
          });
          console.log(res);
          setAllAuthors((prevAllAuthors) => {
              let newAllAuthors = prevAllAuthors.slice();
              const aind = newAllAuthors.findIndex((a) => (a.id === currentAuthorId))
              newAllAuthors[aind]=res;
              return newAllAuthors;
          });
        } catch (err) {
          console.log(err.response?.data?.message);
          alert('Error: '+err.response?.data?.message);
    
        } finally {
          setLoading(false);
        }
  }

  return (
      // <div>
      //   
      <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose} maxWidth='xl' fullWidth>
          <DialogTitle>Edit author bios</DialogTitle>
          <DialogContent>
            <Box className={classes.authorDialogContainer}>
            
                  <FormControl className={classes.formControl} fullWidth>
                      <InputLabel id="author-dialog-select-label">Author</InputLabel>
                      <Select
                          labelId="author-dialog-select-label"
                          id="author-dialog-select"
                          value={currentAuthorId}
                          onChange={handleAuthorChange}
                          input={<Input />}
                      >
                          <MenuItem value={0}>
                              <em>None</em>
                          </MenuItem>
                          {allAuthors.map((author) => (
                              <MenuItem key={author.id} value={author.id}>{author.name}</MenuItem>
                          ))}
                      </Select>
                  </FormControl>
                  <FormControl className={classes.formControl} fullWidth>
                  <TextField
                      required
                      id="outlined-required"
                      label="Author minibio"
                      value={newMinibio}
                      onChange={(ev) => setNewMinibio(ev.target.value)}
                      variant="outlined"
                      rows={7}
                      multiline
                  />
                  </FormControl>
                  <FormControl className={classes.formControl} fullWidth>
                  <TextField
                      required
                      id="outlined-required"
                      label="Author Full bio"
                      value={newFullbio}
                      onChange={(ev) => setNewFullbio(ev.target.value)}
                      variant="outlined"
                      multiline
                      rows={20}
                  />
                  </FormControl>
                  </Box>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose} color="default">
                  Cancel
        </Button>
              <Button onClick={handleUpdateAuthor} color="primary" disabled={loading}>
                  UPDATE
        </Button>
          </DialogActions>
      </Dialog>
  );
}

export default function Admin({user}) {
  const [loading, setLoading] =useState(false);
  const [allTexts, setAllTexts]= useState([]);
  const [allGenre, setAllGenre]= useState([]);


  const [gnrDlgOpen, setGnrDlgOpen] = useState(false);
  const [authorDlgOpen, setAuthorDlgOpen] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const allTexts = await API.get(apiEndPoint, '/client/texts'),
          allGenre = await API.get(apiEndPoint, '/client/genre');

        console.log(allTexts);
        console.log(allGenre);
        setAllTexts(allTexts);
        setAllGenre(allGenre);
        

      } catch (err) {
        alert('An error occured', err.message);
        console.log(err);
      }
      finally {
        setLoading(false);
      }
    }
    loadData();

  }, []);


  return (<Container maxWidth="xl">
    {checkIfIsAdmin(user) ? (<React.Fragment>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingBottom: '1rem',  paddingTop: '1rem' }}>
        <Button onClick={() => setAuthorDlgOpen(true)} variant='contained' color="primary" size="small" style={{ marginRight: '10px' }}>Update Author Bios</Button>
        <Button onClick={() => setGnrDlgOpen(true)} variant='contained' color="primary" size="small" style={{ marginRight: '10px' }}>Update Genre list</Button>
        <AmplifySignOut />
      </div>
      <TextsTable allTexts={allTexts} allGenre={allGenre} />
      <UpdateGenre open={gnrDlgOpen} setOpen={setGnrDlgOpen} allGenre={allGenre} setAllGenre={setAllGenre} />
      <UpdateAuthor open={authorDlgOpen} setOpen={setAuthorDlgOpen} />
      <Backdrop style={{
        zIndex: 1000,
        color: '#fff'
      }} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>) : <React.Fragment><h3>Not authorised</h3><AmplifySignOut /></React.Fragment>}
  </Container>)
  }
  

  