import * as d3 from "d3";

const D3Circle = {};


const wrap = (text, width) => {
    text.each(function (dt) {
        if (dt && (dt.fieldLayer === 'text'))
            {
                d3.select(this).attr("dy","15");
                return;
            }
        var text = d3.select(this),
            words = text.text().split(/\s+/).reverse(),
            nWords=words.length,
            word,
            line = [],
            //lineNumber = 0,
            lineHeight = 1.05, // ems
            y = text.attr("y"),
            ox = text.attr("x"),
            dx = text.attr("dx"),
            dy = parseFloat(text.attr("dy")),
            tspan = text.text(null).append("tspan").attr("x", ox).attr("y", y).attr("dy", dy + "em").attr("dx", dx);

        while (word = words.pop()) { // eslint-disable-line no-cond-assign
            line.push(word);
            tspan.text(line.join(" "));
            if (((nWords === 2)&&(words.length===0)) || (tspan.node().getComputedTextLength() > width)) { //breaks a line
                line.pop();
                tspan.text(line.join(" "));
                line = [word];
                //lineNumber++;
                tspan = text.append("tspan").attr("x", ox).attr("y", y).attr("dy",  (lineHeight) + "em").text(word).attr("dx", dx);
            }
        }

    });
}

D3Circle.create = (el, data, configuration) => {
    // D3 Code to create the chart
    const width = 500;
    const height = 500;
    const radius = width / 1.67;
    //const armRadius = radius / 20;
    const dotRadius = radius / 10;//armRadius - 9;
    //const color = d3.scaleSequential([0, 2 * Math.PI], d3.interpolateSinebow);
    //const color = d3.scaleOrdinal(d3.schemeSet2);
    const highLightOpacity=0.9;
    const fadeOpacity=0.2;
    //const fadeDelay=15*1000; //miliseconds
    const color = d3.scaleOrdinal(["#CC0000", "#ffe402", "#000000"]);
    const fields = [ // : 
        { layer: 'author', radius: 0.35 * radius, tickRadius: (dotRadius * 0.85) , tickElements: data.authors },
        { layer: 'genre', radius: 0.59 * radius, tickRadius: (dotRadius * 0.95 ) , tickElements: data.genres },
        { layer: 'text', radius: 0.78 * radius, tickRadius: (dotRadius/3.5) , tickElements: data.texts } 
    ];

    const svg = d3.select(el).append("svg")
        .attr("viewBox", [0, 0, width, height])
        .attr("text-anchor", "middle")
        .style("display", "block")
        .style("width", "100%")
        .style("height", "100%")
        .style("font-size", "12px");



    const field = svg.append("g")
        .attr("transform", `translate(${width / 2},${height / 2})`)
        .selectAll("g")
        .data(fields)
        .join("g");

    field.append("circle")
        .attr("fill", "none")
        .attr("stroke", "currentColor")
        .attr("stroke-width", 1.0)
        .attr("stroke-dasharray", "5, 5")
        .attr("r", d => d.radius);

    const getTickClass = (d) => {
        let cl=d.fieldLayer+"-tick";
        if (d.fieldLayer === 'author')
          cl+=" author_"+d.elem.id;
        else if (d.fieldLayer === 'text')
          {
              cl+=" author_"+d.elem.author_id;
              d.elem.mapped_ids.forEach((e)=>{
                  cl+= (" gnr_"+ e)
              });
        } else if (d.fieldLayer === 'genre')
        {
            cl+=" genre_"+d.elem.id;
        }
       return cl;
    }

    const highlightAuthorTexts = (currentElem,datum,toggle) => {
        // const t = d3.transition().delay(fadeDelay);//duration(8000).ease(d3.easeLinear); // 
        
        d3.select(currentElem).select("ellipse").attr('fill', ( toggle ? "currentColor" : "white" ));
        d3.select(currentElem).select("text").attr('fill',  ( toggle ? "white" : "black" ));

        const textSelector= ".text-tick.author_"+datum.elem.id;
        d3.selectAll(textSelector).select("ellipse").attr("opacity", ( toggle ? highLightOpacity : fadeOpacity ));


    }

    const highlightGenreText = (currentElem,datum,toggle) => {

        d3.select(currentElem).select("ellipse").attr('fill', (toggle ? "currentColor" : "white" ));
        d3.select(currentElem).select("text").attr('fill', (toggle ? "white" : "black" ));

        const gnrSelector= ".gnr_"+datum.elem.id;
        d3.selectAll(gnrSelector).select("ellipse").attr("opacity", ( toggle ? highLightOpacity : fadeOpacity ));
        //d3.selectAll(gnrSelector).select("text").attr("visibility",( toggle ? "visible" : "hidden" ));


        // const t = d3.transition().delay(fadeDelay);//duration(8000).ease(d3.easeLinear); 


    }

    const highLightText = (currentElem,datum,toggle) => { //(toggle ? :)
        //d3.select(currentElem).attr("opacity", ( toggle ? highLightOpacity : fadeOpacity ));
        d3.select(currentElem).select("text").attr("visibility", (toggle ? "visible" : "hidden") );
        d3.select(currentElem).select("ellipse").attr("stroke-width", (toggle ? 5 : 1) );
        //d3.select(currentElem).select("ellipse").attr("stroke", (toggle ? "green" : "currentColor") );

        const authorSelector= ".author-tick.author_"+datum.elem.author_id;
        d3.select(authorSelector).select("ellipse")
        .attr("stroke-width", (toggle ? 5 : 1) ); //.attr('fill', (toggle ?  "currentColor" : "white" ));
        //.attr("stroke", (toggle ? "green" : "currentColor") );
        //d3.select(authorSelector).select("text").attr('fill',(toggle ? "white" : "black") );
        if (datum.elem.mapped_ids.length > 0) {
             const genreSelector = datum.elem.mapped_ids.map((id) => ('.genre_' + id)).join(', ');
             d3.selectAll(genreSelector).select("ellipse").attr("stroke-width", (toggle ? 5 : 1) );// .attr('fill', (toggle ?  "currentColor" : "white" ));
        //     d3.select(genreSelector).select("text").attr('fill', (toggle ? "white" : "black"));
         }


    }

    const fieldTick = field.selectAll("g")
        .data(d => {
            return d.tickElements.map(el => ({ 'elem': el, 'fieldLayer': d.layer, 'fieldRaius': d.radius, 'ticksLength': d.tickElements.length, 'fieldTickRadius': d.tickRadius  }));
        })
        .join("g")
        .attr("class", (d) => getTickClass(d))
        .attr("transform", (d, i) => {
            const angle = i / d.ticksLength * 2 * Math.PI - Math.PI / 2;
            return `translate(${Math.cos(angle) * d.fieldRaius},${Math.sin(angle) * d.fieldRaius})`;
        })
        .style("cursor", "pointer")
        .on("dblclick", function (elem, d, nodes) {
            if (d.fieldLayer === 'author')
                configuration.showAuthorCB(d.elem);
        })
        .on("click",function (elem, d, nodes) {
            const isToggled=!(d3.select(this).classed("turnedon"));
            if (d.fieldLayer === 'author') {
                highlightAuthorTexts(this,d,isToggled);
            }else if (d.fieldLayer === 'genre') {
                highlightGenreText(this,d,isToggled);
            }
            d3.select(this).classed("turnedon", !d3.select(this).classed("turnedon"));
        } )
        .on("mouseenter", function (elem, d, nodes) {
            //console.log('me',d.fieldLayer);
            if (d.fieldLayer === 'text')
            {
               highLightText(this,d,true);
            }
        })
        .on("mouseleave", function (elem, d, i) {
            //console.log('ml',d.fieldLayer);
            if (d.fieldLayer === 'text')
            {
                highLightText(this,d,false);
            } 
        });;

    fieldTick.append("ellipse")
        .attr("rx", d => (d.fieldTickRadius))
        .attr("ry", d => (d.fieldTickRadius))
        .attr("opacity", (d) => (d.fieldLayer === 'text' ? fadeOpacity : 1))
        .style("color", (d, i) => color(i / d.ticksLength * 2 * Math.PI))
        .attr("fill", (d)=>(d.fieldLayer === "text" ? "currentColor": "white"))
        .attr("stroke", "currentColor")
        .style("transition", "fill 750ms ease-out");


    fieldTick.append("text")
        .style("font-weight", d => (d.fieldLayer === 'author' ? 'bold' : 'bold'))
        .style("font-size", d => (d.fieldLayer === 'author' ? '8px' : '8px'))
        // .attr("dx", (d,i) => {
        //     if (d.fieldLayer === 'author')
        //         return '0.0em'
        //     else {
        //         const angle= i / d.ticksLength * 2 * Math.PI - Math.PI / 2;

        //         return d.fieldTickRadius * 4;
        //     }
        // })
        .attr("transform", (d, i) => {
            if (d.fieldLayer !== 'text')
                return '';
            // const angle = i / d.ticksLength * 2 * Math.PI - Math.PI / 2;
            // const length= d.fieldTickRadius *2.5;
            // return `translate(${Math.cos(angle) * length},${Math.sin(angle) * length})`;
            return ``;
        })
        .attr("dy", "-0.6em")
        .attr("fill", "#222")
        .attr("x", 0)
        .attr("visibility", d => ( d.fieldLayer === "text" ? "hidden": "visible"))
        //.attr("text-rendering","auto")
        .text(d => getTickText(d))
        .call(wrap, dotRadius + 18);


        // //let t0=Date.now();
        // d3.timer(function (elapsed) {
        //     //let delta = Date.now() - t0;
        //     let speed= 5;
          
        //     // d3.selectAll('.author-tick')
        //     //   .attr("transform", (d, i) => {
        //     //     const angle = (i / d.ticksLength * 2 * Math.PI - Math.PI / 2 +(elapsed * speed / 100000));
        //     //     //return `translate(${Math.cos(angle) * d.fieldRaius},${Math.sin(angle) * d.fieldRaius})`;
        //     //     return `translate(${d.fieldRaius}) rotate(${angle})`;
        //     // });

        // //     d3.selectAll('.genre-tick')
        // //     .attr("transform", (d, i) => {
        // //       const angle = i / d.ticksLength * 2 * Math.PI - Math.PI / 2 -(elapsed * speed / 100000);
        // //       return `translate(${Math.cos(angle) * d.fieldRaius},${Math.sin(angle) * d.fieldRaius})`;
        // //   });

        //   });

};

const getTickText = (d) =>{
    if (d.fieldLayer === "text")
      return (d.elem.publication_title+ ', '+d.elem.date_year);
    else if (d.fieldLayer === "author")
       return (d.elem.name)
      else if (d.fieldLayer === "genre")
       return d.elem.genre
    
}

D3Circle.update = (el, data, configuration, chart) => {
    // D3 Code to update the chart
};

D3Circle.destroy = () => {
    // Cleaning code here
};

export default D3Circle;